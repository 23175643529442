@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Open+Sans:wght@400;600;700&family=Poppins:wght@400;500;700&display=swap');

:root {
  --tecnofi-primary: #188f4b;
  --tecnofi-secondary: #70c031;
  --tecnofi-dark: #17202B;
  --tecnofi-text: #313532; /* Gris oscuro para texto */
  --tecnofi-faded-text: #B0B7BD; /* Texto desvanecido */
  --tecnofi-background: #F9F9F9; /* Color de fondo */
  --tecnofi-section: #EEE; /* Color de sección */
}

[data-theme='dark'] {
  --tecnofi-primary: #41a114;
  --tecnofi-secondary: #0E6631;
  --tecnofi-dark: #0B1219;
  --tecnofi-text: #d0d6dd;
  --tecnofi-faded-text: #90979D;
  --tecnofi-background: #1C1C1C;
  --tecnofi-section: #333;
}

body {
  margin: 0;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::selection {
  background-color: rgba(111, 192, 49, 0.65);
  color: white;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--tecnofi-faded-text);
  border-radius: 4px;
}